html {
	overflow-y: scroll;
}

/* Light mode */
@media (prefers-color-scheme: light) {
	body {
		background-color: #fffffff0;
		color: #000000f0;
	}
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
	body {
		background-color: #000000f0;
		color: #fffffff0;
	}
}

body {
	font-family: 'IBM Plex Mono', monospace;
	font-size: 1.01em;
	margin-left: 20%;
	margin-right: 20%;
	min-width: 500px;
}

@media only screen and (max-width: 700px) {
	body {
		margin-left: 0%;
		margin-right: 0%;
	}
}

.centered {
	text-align: center;
}

h1 {
	font-family: 'IBM Plex Mono', monospace;
	font-size: 2em;
	text-align: center;
}

h2 {
	font-family: 'IBM Plex Mono', monospace;
	font-size: 1.4em;
	text-align: right;
	margin-top: 30px;
}

/* 
   Allows for responsive re-sizing of embedded iframes
   primarily for youtube videos
*/
.iframewrapper {
	position: relative;
	padding-bottom: 56.25%;
	/* 16:9 */
	height: 0;
}

.iframewrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.caption {
	text-align: center;
	font-style: italic;
	font-size: small;
	margin-bottom: 30px;
}

a {
	cursor: pointer;
	color: inherit;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

pre>code {
	font-size: 1.4em;
}

.ascii-art {
	/* for ascii art */
	white-space: pre;
	text-align: center;
}

.load-more-button {
	text-align: center;
}

.load-more-button>button {
	font-family: 'IBM Plex Mono', monospace;
	font-size: 1.4em;
	margin-bottom: 5%;
}