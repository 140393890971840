html {
  overflow-y: scroll;
}

@media (prefers-color-scheme: light) {
  body {
    color: #000000f0;
    background-color: #fffffff0;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fffffff0;
    background-color: #000000f0;
  }
}

body {
  min-width: 500px;
  margin-left: 20%;
  margin-right: 20%;
  font-family: IBM Plex Mono, monospace;
  font-size: 1.01em;
}

@media only screen and (width <= 700px) {
  body {
    margin-left: 0%;
    margin-right: 0%;
  }
}

.centered {
  text-align: center;
}

h1 {
  text-align: center;
  font-family: IBM Plex Mono, monospace;
  font-size: 2em;
}

h2 {
  text-align: right;
  margin-top: 30px;
  font-family: IBM Plex Mono, monospace;
  font-size: 1.4em;
}

.iframewrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.iframewrapper iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.caption {
  text-align: center;
  margin-bottom: 30px;
  font-size: small;
  font-style: italic;
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

pre > code {
  font-size: 1.4em;
}

.ascii-art {
  white-space: pre;
  text-align: center;
}

.load-more-button {
  text-align: center;
}

.load-more-button > button {
  margin-bottom: 5%;
  font-family: IBM Plex Mono, monospace;
  font-size: 1.4em;
}
/*# sourceMappingURL=index.e2dda652.css.map */
